import { useTranslation } from "@lib/useTypedTranslation";

import { ZeroSignalMap } from "../../../components/intensityMaps/zeroSignal";
import { NoServiceMap } from "../../../components/intensityMaps/noService";
import { Loading } from "../../../components/loading/loading";
import { TimePeriodAndFilterPicker } from "../../../components/timePeriodAndFilterPicker/timePeriodAndFilterPicker";
import { ShowMapFeatureToggles, ToggleableFeatures } from "../../../context/featureToggles";
import { useFilterPicker } from "../../../components/timePeriodAndFilterPicker/useFilterPicker";
import { useTimePeriodPicker } from "../../../components/timePeriodAndFilterPicker/useTimePeriodPicker";
import React from "react";
import { CoverageMap, CoverageMapProps } from "@components/intensityMaps/coverage";
import { OptionPicker } from "@components/controls/optionPicker";
import { useWorldRequest } from "@lib/useWorldRequest";
import { getBearerTypes, getSimProviders } from "../../../services/core/maps";
import { Option } from "@components/controls/optionPickerLib";
import { ChipButton } from "@components/controls/chipButton";

export const PoorNoSignalTab = () => {
  const { t } = useTranslation('maps');

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('VERY_POOR_NO_SERVICE_SUBHEADING')}</h2>
      </div>
      <TimePeriodAndFilterPicker
        filter={filter}
        {...filterPickerProps}
        timePeriod={timePeriod}
        {...timePeriodPickerProps}
        className='core-maps_time-and-estate-filters'
      />
    </div>
    <Loading isLoading={optionsLoading} transparentOverlay={false}>
      <ZeroSignalMap
        groups={!filter.group ? undefined : [filter.group.value]}
        homeLocations={!filter.homeLocation ? undefined : [filter.homeLocation.value.id]}
        zoneId={filter.zone?.value.id}
        timePeriod={timePeriod.value}
      />
    </Loading>
  </>;
};

export const NoServiceTab = () => {
  const { t } = useTranslation('maps');

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('NO_SERVICE_SUBHEADING')}</h2>
      </div>
      <TimePeriodAndFilterPicker
        filter={filter}
        {...filterPickerProps}
        timePeriod={timePeriod}
        {...timePeriodPickerProps}
        className='core-maps_time-and-estate-filters'
      />
    </div>
    <Loading isLoading={optionsLoading} transparentOverlay={false}>
      <NoServiceMap
        groups={!filter.group ? undefined : [filter.group.value]}
        homeLocations={!filter.homeLocation ? undefined : [filter.homeLocation.value.id]}
        zoneId={filter.zone?.value.id}
        timePeriod={timePeriod.value}
      />
    </Loading>
  </>;
};

export const CoverageTab = () => {
  const { t } = useTranslation('maps');

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();
  const { group, homeLocation, zone } = filter;

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 7, 2] });

  const simProvidersRequest = React.useCallback(() => getSimProviders({ timePeriod: 30 }), []);
  const { data: simProviders, loading: simProvidersLoading } = useWorldRequest(simProvidersRequest);
  const simProviderOptions = React.useMemo(() => {
    const apiOptions = simProviders?.map(i => ({ name: i, value: i })) ?? [];
    return [{ name: t('ALL_SIM_PROVIDERS'), value: undefined }, ...apiOptions];
  }, [simProviders, t]);
  const [simProvider, setSimProvider] = React.useState<Option>(simProviderOptions[0]);

  const bearerTypesRequest = React.useCallback(() => getBearerTypes({ timePeriod: 30 }), []);
  const { data: bearerTypes, loading: bearerTypesLoading } = useWorldRequest(bearerTypesRequest);
  const allBearerTypes = ['2g', '3g', '4g', '5g'];
  const defaultBearerValue = React.useMemo(() => {
    if (bearerTypes?.length) {
      return bearerTypes.sort((a, b) => a.count - b.count)[bearerTypes.length - 1].bearerType;
    }
  }, [bearerTypes]);

  const [selectedBearer, setSelectedBearer] = React.useState<string>(defaultBearerValue);

  React.useEffect(() => {
    if (selectedBearer === undefined && defaultBearerValue) {
      setSelectedBearer(defaultBearerValue);
    }
  }, [selectedBearer, defaultBearerValue]);

  const coverageMapProps = React.useMemo<CoverageMapProps>(() => {
    return {
      groups: !group ? undefined : [group.value],
      homeLocations: !homeLocation ? undefined : [homeLocation.value.id],
      zoneId: zone?.value.id,
      timePeriod: timePeriod.value,
      simProvider: simProvider?.value,
      bearerType: selectedBearer
    };
  }, [group, homeLocation, zone, timePeriod, simProvider, selectedBearer]);

  return <>
    <div className="core-maps_header">
      <div className="core-maps_headings">
        <h2>{t('COVERAGE_SUBHEADING')}</h2>
      </div>
      <div className="core-maps_filters">
        <div className="core-maps_coverage-filters">
          <OptionPicker
            options={simProviderOptions}
            onSelect={setSimProvider}
            current={simProvider}
            disabled={simProvidersLoading}
            id="sim-provider-picker"
          />
          <div className="radio-buttons">
            {allBearerTypes.map((bearerType => {
              const selected = bearerType === selectedBearer;

              return <ChipButton
                key={bearerType}
                text={bearerType.toUpperCase()}
                active={selected}
                disabled={bearerTypesLoading || selectedBearer === undefined}
                onClick={() => {
                  if (bearerType !== selectedBearer) {
                    setSelectedBearer(bearerType);
                  }
                }}
              />;
            }))}
          </div>
        </div>
        <TimePeriodAndFilterPicker
          filter={filter}
          {...filterPickerProps}
          timePeriod={timePeriod}
          {...timePeriodPickerProps}
          className='core-maps_time-and-estate-filters'
        />
      </div>
    </div>
    <Loading isLoading={optionsLoading || simProvidersLoading || bearerTypesLoading} transparentOverlay={false}>
      <CoverageMap
        {...coverageMapProps}
      />
    </Loading>
  </>;
};

export interface ITabMap {
  type: ShowMapFeatureToggles,
  tab: React.FC,
  title: string
}

export const tabsMap = [
  { type: ToggleableFeatures.showMapNoService, title: 'NO_SERVICE_TAB' as const, tab: NoServiceTab },
  { type: ToggleableFeatures.showMapPoorNoSignal, title: 'VERY_POOR_NO_SERVICE_TAB' as const, tab: PoorNoSignalTab },
  { type: ToggleableFeatures.showMapCoverage, title: 'COVERAGE_TAB' as const, tab: CoverageTab }
] as const;
