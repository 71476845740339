import React, { useContext } from 'react';

export const FeatureTogglesContext = React.createContext<FeatureTogglesContextValue>(undefined);

interface FeatureTogglesProviderProps {
  value: FeatureTogglesContextValue,
  children: React.ReactNode
}

export enum ToggleableFeatures {
  showMapsTab = 'show-maps-tab',
  showMapPoorNoSignal = 'show-map-poor-no-signal',
  showDeviceCoverage = 'show-device-coverage',
  hideEnrolmentPage = 'hide-enrolment-page',
  hideUserManagement = 'hide-user-management',
  deviceNetworkWindows = 'device-network-windows',
  deviceNetworkAndroid = 'device-network-android',
  showMapNoService = 'show-map-no-service',
  showMapCoverage = 'show-map-coverage',
  showMobileSignalReporting = 'show-mobile-signal-reporting',
}

export type ShowMapFeatureToggles = ToggleableFeatures.showMapNoService | ToggleableFeatures.showMapPoorNoSignal | ToggleableFeatures.showMapCoverage;


export interface FeatureTogglesContextValue {
  hideEnrollmentPage: boolean,
  hideUserManagement: boolean,
  showMapsTab: boolean,
  showMap: (mapType: ShowMapFeatureToggles) => boolean,
  showDeviceCoverage: boolean,
  getDeviceSignalEnabled: (platformType: string) => boolean,
  getDropsEnabledOnDevice: (platformType: string) => boolean,
  showMobileSignalReporting: boolean
}

export const FeatureTogglesProvider = ({ children, value }: FeatureTogglesProviderProps) => {
  return (
    <FeatureTogglesContext.Provider value={value}>
      {children}
    </FeatureTogglesContext.Provider>
  );
};

export function useFeatureTogglesContext() {
  const context = useContext(FeatureTogglesContext);
  if (context === undefined) {
    throw new Error('useFeatureTogglesContext must be used within a FeatureTogglesProvider');
  }
  return context;
}
