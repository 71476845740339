import React, { useMemo } from 'react';
import { FeatureTogglesProvider, ShowMapFeatureToggles, ToggleableFeatures } from '../../context/featureToggles';

interface Props {
  featureToggles: string[],
  children: React.ReactNode
}

export function FeatureTogglesWrapper(props: Props) {
  const { children, featureToggles } = props;
  const value = useMemo(() => {
    return {
      hideEnrollmentPage: featureToggles.includes('hide-enrolment-page'),
      hideUserManagement: featureToggles.includes('hide-user-management'),
      showMapsTab: featureToggles.includes('show-maps-tab'),
      showDeviceCoverage: featureToggles.includes('show-device-coverage'),
      showMap: (mapType: ShowMapFeatureToggles) => {
        return featureToggles.includes(mapType);
      },
      getDeviceSignalEnabled: (platformType: string) => {
        const isAndroidAndEnabled = platformType === 'android' && featureToggles.includes(ToggleableFeatures.deviceNetworkAndroid);
        const isWindowsAndEnabled = platformType === 'windows' && featureToggles.includes(ToggleableFeatures.deviceNetworkWindows);
        return isAndroidAndEnabled || isWindowsAndEnabled;
      },
      getDropsEnabledOnDevice: (platformType: string) => platformType === 'android',
      showMobileSignalReporting: featureToggles.includes(ToggleableFeatures.showMobileSignalReporting)
    };
  }, [featureToggles]);
  return (
    <FeatureTogglesProvider value={value}>
      {children}
    </FeatureTogglesProvider>
  );
}
